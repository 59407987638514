// src/pages/About.jsx
import React from 'react';
import { Shield, Zap, Smartphone, Check } from 'lucide-react';
import FAQ from '../components/FAQ';
import productImage1 from '../images/noBGImages/IMG_9349.png';
import productImage2 from '../images/noBGImages/IMG_0054-nobg.png';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const navigate = useNavigate();

  const handlePurchaseClick = () => {
    navigate('/Purchase');
    window.scrollTo(0, 0);
  };

  return (
    <div className="min-h-screen overflow-x-hidden">
      {/* Hero Section - Optimized for iPhone */}
      <section className="relative min-h-[400px] sm:min-h-[500px] md:min-h-[600px] bg-gradient-to-br from-blue-200 via-blue-100 to-emerald-200">
        <div className="container mx-auto px-4 pt-6 sm:pt-10 md:pt-20 pb-8 sm:pb-16 md:pb-32 grid md:grid-cols-2 gap-4 sm:gap-8 items-center">
          <div className="max-w-xl text-center md:text-left">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-3 sm:mb-4">How People with Severe Allergies Stay Protected</h2>
            <p className="text-base sm:text-lg md:text-xl mb-6 sm:mb-8">
              Keep your life-saving medication secure and instantly accessible when every second counts
            </p>
            <div className="space-y-3 sm:space-y-4">
              <div className="flex flex-wrap justify-center md:justify-start gap-4">
                <button 
                  onClick={handlePurchaseClick}
                  className="w-full sm:w-auto bg-green-600 hover:bg-green-700 text-white px-4 py-3 rounded text-base sm:text-lg transition-colors duration-200"
                >
                  Get Peace of Mind - $15.99
                </button>
              </div>
              <p className="text-xs sm:text-sm">Free Shipping Included</p>
            </div>
          </div>
          <div className="relative w-full h-[250px] sm:h-[300px] md:h-[400px] flex justify-center gap-4">
            <img
              src={productImage2}
              alt="EpiQCase product - Second view"
              className="object-contain w-1/2 h-full scale-125 sm:scale-150"
            />
            <img
              src={productImage1}
              alt="EpiQCase product - A black protective case for AuviQ"
              className="object-contain w-1/2 h-full scale-[2] sm:scale-[3]"
            />
          </div>
        </div>
      </section>

      {/* PAS Framework Section */}
      <section className="py-8 sm:py-12 md:py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-6 md:gap-8">
            <div className="text-center p-4 sm:p-6">
              <h3 className="text-lg sm:text-xl font-semibold mb-2 sm:mb-4 text-red-600">The Problem</h3>
              <p className="text-sm sm:text-base text-gray-600">
                Carrying an AuviQ can be stressful. It's bulky, easy to forget, and hard to access quickly in
                emergencies.
              </p>
            </div>
            <div className="text-center p-4 sm:p-6">
              <h3 className="text-lg sm:text-xl font-semibold mb-2 sm:mb-4 text-amber-600">The Risk</h3>
              <p className="text-sm sm:text-base text-gray-600">
                Without quick access to your auto-injector, a severe allergic reaction can become life-threatening in
                minutes.
              </p>
            </div>
            <div className="text-center p-4 sm:p-6">
              <h3 className="text-lg sm:text-xl font-semibold mb-2 sm:mb-4 text-green-600">The Solution</h3>
              <p className="text-sm sm:text-base text-gray-600">
                EpiQCase keeps your AuviQ secure, accessible, and always with you - attached right to your phone.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Features and Benefits Section */}
      <section className="py-8 sm:py-12 md:py-20">
        <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-center mb-6 sm:mb-8 md:mb-16 px-4">How EpiQCase Protects You</h2>
        <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-8 md:gap-12">
          <div className="space-y-4 sm:space-y-6">
            <h3 className="text-lg sm:text-xl md:text-2xl font-semibold">Features</h3>
            <div className="space-y-3 sm:space-y-4">
              <div className="flex items-start gap-3">
                <Shield className="w-5 h-5 sm:w-6 sm:h-6 text-green-600 flex-shrink-0 mt-1" />
                <div>
                  <h4 className="font-medium text-sm sm:text-base">Secure Protection</h4>
                  <p className="text-xs sm:text-sm text-gray-600">Custom-molded case with strong adhesive backing</p>
                </div>
              </div>
              <div className="flex items-start gap-3">
                <Zap className="w-5 h-5 sm:w-6 sm:h-6 text-green-600 flex-shrink-0 mt-1" />
                <div>
                  <h4 className="font-medium text-sm sm:text-base">Quick-Release Mechanism</h4>
                  <p className="text-xs sm:text-sm text-gray-600">Instant access with one-handed operation</p>
                </div>
              </div>
              <div className="flex items-start gap-3">
                <Smartphone className="w-5 h-5 sm:w-6 sm:h-6 text-green-600 flex-shrink-0 mt-1" />
                <div>
                  <h4 className="font-medium text-sm sm:text-base">Universal Compatibility</h4>
                  <p className="text-xs sm:text-sm text-gray-600">Attaches to phones, cases, or any flat surface</p>
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-4 sm:space-y-6">
            <h3 className="text-lg sm:text-xl md:text-2xl font-semibold">Benefits</h3>
            <div className="space-y-3 sm:space-y-4">
              <div className="flex items-start gap-3">
                <Check className="w-5 h-5 sm:w-6 sm:h-6 text-blue-600 flex-shrink-0 mt-1" />
                <div>
                  <h4 className="font-medium text-sm sm:text-base">Never Forget Your AuviQ</h4>
                  <p className="text-xs sm:text-sm text-gray-600">Always have it with you when you grab your phone</p>
                </div>
              </div>
              <div className="flex items-start gap-3">
                <Check className="w-5 h-5 sm:w-6 sm:h-6 text-blue-600 flex-shrink-0 mt-1" />
                <div>
                  <h4 className="font-medium text-sm sm:text-base">Save Critical Seconds</h4>
                  <p className="text-xs sm:text-sm text-gray-600">Access your medication instantly when time matters most</p>
                </div>
              </div>
              <div className="flex items-start gap-3">
                <Check className="w-5 h-5 sm:w-6 sm:h-6 text-blue-600 flex-shrink-0 mt-1" />
                <div>
                  <h4 className="font-medium text-sm sm:text-base">Peace of Mind</h4>
                  <p className="text-xs sm:text-sm text-gray-600">Feel confident knowing your AuviQ is always secure and accessible</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-8 sm:py-12 md:py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <FAQ />
        </div>
      </section>

      {/* Final CTA Section */}
      <section className="py-8 sm:py-12 md:py-20 bg-gradient-to-br from-blue-200 via-blue-100 to-emerald-200">
        <div className="container mx-auto px-4 text-center max-w-3xl">
          <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6 text-gray-800">Ready to Secure Your Peace of Mind?</h2>
          <button className="w-full sm:w-auto bg-green-600 hover:bg-green-700 text-white px-4 sm:px-6 py-3 rounded-lg text-base sm:text-lg font-semibold">
            Get Your EpiQCase Today - $15.99
          </button>
          <p className="mt-3 sm:mt-4 text-xs sm:text-sm text-gray-600">Free Shipping Included</p>
        </div>
      </section>
    </div>
  );
}